<template>
    <section v-if="accountProfile">
        <fieldset v-if="!editMode" class="flex flex-col gap-2">
            <MyAccountLegend :help-link="helpLink">
                {{ $t("MyAccount.MyInformationFieldset") }}
            </MyAccountLegend>
            <label for="UserName" class="font-bold">{{ $t("MyAccount.UsernameLabel") }}</label>
            <p id="UserName" class="text-xl">
                {{ accountProfile.UserName }}
            </p>
            <label for="Name" class="font-bold">{{ $t("MyAccount.NameLabel") }}</label>
            <p id="Name" class="text-xl">
                {{ accountProfile.Name }}
            </p>
            <label for="CompanyName" class="font-bold">{{ $t("MyAccount.CompanyLabel") }}</label>
            <p id="CompanyName" class="text-xl">
                {{ accountProfile.CompanyName }}
            </p>
            <label for="Email" class="font-bold">{{ $t("MyAccount.EmailLabel") }}</label>
            <p id="Email" class="text-xl">
                {{ accountProfile.EmailAddress }}
            </p>
            <label for="PromotionalEmailsEnabled" class="font-bold">{{ $t("MyAccount.PromotionalEmailsLabel") }}</label>
            <p id="Email" class="text-xl">
                {{ accountProfile.PromotionalEmailsEnabled ? $t("Global.Yes") : $t("Global.No") }}
            </p>
            <div class="flex gap-4">
                <Button variant="primary" fill="solid" @click="editUser()">
                    {{ $t("MyAccount.EditProfileButton") }}
                </Button>
                <Dialog>
                    <DialogTrigger as-child>
                        <Button variant="danger" class="gap-2">
                            <i class="fas fa-trash-can" />
                            {{ $t("MyAccount.DeleteMyAccountLabel") }}
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{{ $t("Global.Confirm") }}</DialogTitle>
                        </DialogHeader>
                        <div>{{ $t("MyAccount.ModalWarning") }}</div>
                        <DialogFooter>
                            <form class="flex gap-2" @submit.prevent="onDeleteSubmit">
                                <DialogClose as-child>
                                    <Button variant="default">{{ $t("Global.Cancel") }}</Button>
                                </DialogClose>
                                <Button type="submit" variant="danger" fill="solid">
                                    <span>{{ $t("MyAccount.DeleteMyAccountConfirmLabel") }}</span>
                                </Button>
                            </form>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        </fieldset>

        <form v-if="editedCurrentUser && editMode" id="profile-form" novalidate @submit.prevent="onEditSubmit">
            <fieldset class="flex flex-col gap-2">
                <MyAccountLegend :help-link="helpLink">
                    {{ $t("MyAccount.MyInformationFieldset") }}
                </MyAccountLegend>
                <label for="UserName" class="font-bold">{{ $t("MyAccount.UsernameLabel") }}</label>
                <p id="UserName" class="text-xl">
                    {{ accountProfile.UserName }}
                </p>
                <label for="Name" class="font-bold">{{ $t("MyAccount.NameLabel") }}</label>
                <input id="Name" v-model="editedCurrentUser.Name" type="text" name="Name" />
                <span class="text-danger">{{ issueMessages["Name"] }}</span>

                <label for="CompanyName" class="font-bold">{{ $t("MyAccount.CompanyLabel") }}</label>
                <input id="CompanyName" v-model="editedCurrentUser.CompanyName" type="text" name="CompanyName" />

                <label for="Email" class="font-bold">{{ $t("MyAccount.EmailLabel") }}</label>
                <input id="Email" v-model="editedCurrentUser.EmailAddress" type="email" name="Email" />
                <span class="text-danger">{{ issueMessages["EmailAddress"] }}</span>

                <div class="flex gap-2 items-center mb-4">
                    <label for="PromotionalEmailsEnabled" class="font-bold">{{
                        $t("MyAccount.PromotionalEmailsLabel")
                    }}</label>
                    <input
                        id="PromotionalEmailsEnabled"
                        v-model="editedCurrentUser.PromotionalEmailsEnabled"
                        type="checkbox"
                        name="PromotionalEmailsEnabled"
                        :value="editedCurrentUser.PromotionalEmailsEnabled" />
                </div>
            </fieldset>
            <div class="flex gap-2">
                <Button variant="default" fill="none" @click="editMode = false">
                    {{ $t("Global.Cancel") }}
                </Button>
                <Button type="submit" variant="primary" fill="solid">
                    {{ $t("MyAccount.SaveProfileButton") }}
                </Button>
            </div>
        </form>
    </section>
</template>
<script setup lang="ts">
import type { SafeParseResult } from "valibot";
import * as v from "valibot";

const { t } = useI18n();
const localePath = useLangPath();
const isAuthenticated = useStateIsAuthenticated();
const { data: accountProfile } = await useFetchLocaleApi<UserProfile>("api/account/profile");
const editMode = ref<boolean>(false);
const editedCurrentUser = ref<UserProfile>();
const api = useApi();

const UserProfileSchema = v.object({
    Name: v.pipe(v.string(), v.trim(), v.nonEmpty(t("MyAccount.ErrorNameIsRequired"))),
    EmailAddress: v.pipe(
        v.string(),
        v.trim(),
        v.nonEmpty(t("MyAccount.ErrorEmailIsRequired")),
        v.email(t("MyAccount.ErrorEmailIsInvalid"))
    ),
});

const parseResult = ref<SafeParseResult<typeof UserProfileSchema> | null>(null);

const helpLink = PageHelpers.GetDocsUrl("my-account/");

// maps parseResult issues to key/value pairs
const issueMessages = computed(() =>
    Object.fromEntries(parseResult.value?.issues?.map((i) => [v.getDotPath(i), i.message]) ?? [])
);

function editUser() {
    if (!accountProfile.value) return;

    editedCurrentUser.value = { ...accountProfile.value };
    parseResult.value = null;
    editMode.value = true;
}

async function onDeleteSubmit() {
    if (!accountProfile.value) return;

    try {
        const response = await api<{ Success: boolean }>("api/account/delete", {
            method: "POST",
            body: accountProfile.value.UserName,
        });

        if (response.Success) {
            isAuthenticated.value = false;
            await navigateTo(localePath("/"));
        }
    } catch (err) {
        console.error(err);
    }
}

async function onEditSubmit() {
    if (!editedCurrentUser.value) return;

    parseResult.value = v.safeParse(UserProfileSchema, editedCurrentUser.value);

    if (parseResult.value.success) {
        try {
            accountProfile.value = await api<UserProfile>("api/account/profile", {
                method: "POST",
                body: editedCurrentUser.value,
            });
            editMode.value = false;
        } catch (err) {
            console.error(err);
        }
    }
}
</script>
